<template>
  <a-row type="flex" justify="center">
    <a-col :lg="18" :span="23">
      <div class="treaty">
        <h1>用户协议</h1>
        <hr>
        <div class="content">
          <div>发布日期：2021年【12】月【17】日</div>
          <div>生效日期：2022年【1】月【1】日</div>
        </div>
        <br>
        <h3>关于我们</h3>
        <div>VLLSHOP产品与/或服务平台（以下简称“VLLSHOP”或“本平台”）是由深圳市万鲸科技有限公司（注册地址：【深圳市南山区粤海街道高新区社区高新南九道51号航空航天大厦1号楼703】联系地址：【深圳市南山区航空航天大厦1号楼703】）（以下统称“我们”）拥有并经营，并通过VLLSHOP网站（<a href="https://www.vllshop.com" target="_blank">https://www.vllshop.com</a>）向卖家用户（以下简称“用户”“您”或“卖家”）提供网店建站的SaaS平台服务。</div>
        <br>
        <h3>一、相关事项</h3>
        <div>1. 在您注册成为VLLSHOP用户之前，请访问阅读链接并认真阅读并充分理解本协议具体条款、<a href="https://www.vllshop.com/treaty/contract" target="_blank">《VLLSHOP平台合同》</a>以及<a href="https://www.vllshop.com/treaty/privacyPolicy" target="_blank">《VLLSHOP隐私政策》</a>相关条款，特别是免除或者减轻责任等与您有重大利害关系的条款（特别是以加粗以及加下划线方式提示用户注意的条款）、法律适用和争议解决条款等。</div>
        <br>
        <div>2.<a href="https://www.vllshop.com/treaty/privacyPolicy" target="_blank">《VLLSHOP隐私政策》</a>、<a href="https://www.vllshop.com/treaty/contract" target="_blank">《VLLSHOP平台合同》</a>和其他政策文件、操作规则、协议文件等均为本协议的有效组成部分，与本协议不可分割且具有同等法律效力（以下统称“服务协议”或“本协议”）。本协议是您和我们之间关于您作为用户使用VLLSHOP提供的信息、软件、产品和服务等相关事宜订立的协议。</div>
        <br>
        <div>3.在阅读本协议的过程中，如您有任何疑问或反馈，可以通过VLLSHOP的客户服务热线：400－829－9969联系我们。当您按照注册页面提示填写信息、完成全部注册程序，且阅读并勾选同意本协议，即表示您接受本协议的全部内容，成为VLLSHOP的用户。如果您不同意本服务协议或其中任何条款约定，应立即停止注册程序或使用本平台。</div>
        <br>
        <div>4.我们会根据相关法律法规要求或商业发展需求不时更新本协议，同时会在本平台上通过提前15日的方式进行公布，修改后的内容经公示期后生效并代替原来的协议。因此，我们建议您定期查看本平台服务协议的内容和相关通知，以了解最新的规则。如您不同意变更事项，您有权于变更事项确定的生效日前联系我们反馈意见。如反馈意见被采纳，我们将酌情调整变更事项。如您对已生效的变更事项仍不同意的，您应当于变更事项确定的生效之日起停止使用VLLSHOP服务，变更事项对您不产生效力；如您在变更事项生效后仍继续使用本平台服务，则视为您同意并接受已生效的变更事项。</div>
        <br>
        <div>4.我们会根据相关法律法规要求或商业发展需求不时更新本协议，同时会在本平台上通过提前15日的方式进行公布，修改后的内容经公示期后生效并代替原来的协议。因此，我们建议您定期查看本平台服务协议的内容和相关通知，以了解最新的规则。如您不同意变更事项，您有权于变更事项确定的生效日前联系我们反馈意见。如反馈意见被采纳，我们将酌情调整变更事项。如您对已生效的变更事项仍不同意的，您应当于变更事项确定的生效之日起停止使用VLLSHOP服务，变更事项对您不产生效力；如您在变更事项生效后仍继续使用本平台服务，则视为您同意并接受已生效的变更事项。</div>
        <br>
        <h3>二、服务内容</h3>
        <div>1. 具体服务内容请参考：<a href="https://www.vllshop.com/treaty/contract" target="_blank">《VLLSHOP平台合同》</a></div>
        <br>
        <div>2. 在建站服务中，您可以通过商品置顶、商品推荐、商品ADD TO CART、色板、尺码表等功能自行设置店铺界面。您理解并同意，在您使用VLLSHOP建站服务的过程中，我们可能会收集您的个人信息，以及终端用户的个人信息，具体收集和使用规则请见<a href="https://www.vllshop.com/treaty/privacyPolicy" target="_blank">《VLLSHOP隐私政策》相关条款。</a></div>
        <br>
        <div>3. 您理解并同意，按照计划支付相关费用，包括但不限于按照您的网店月度收入一定比例收取的维护费、购买或使用VLLSHOP不定期提供产品或服务的费用。我们将会通过管理面板、电子邮箱或其他您授权的方式发送收费通知或账单。</div>
        <br>
        <div>4. 您理解并接受，在您为成为VLLSHOP付费会员而需支付VLLSHOP套餐费用，或当您使用自动续费或自动扣款功能时，我们将通过Checkout为您提供为实现上述功能所需的必要服务。我们将严格按照相关法规要求对Checkout的服务进行监督，以确保您的信息不会被非法使用。同时，您应确保您在使用该服务过程中，严格遵守法律法规以及VLLSHOP的相关使用规定。您同意VLLSHOP或Checkout有权在发现您使用该服务并违反了相关法规或规定时，自行决定对您的账户进行相应处置。</div>
        <br>
        <div>5. 如果您违反本协议任何条款和条件，我们保留立即终止您的VLLSHOP用户资格的权利，并不会提前通知您，同时您将不能继续访问本网站以及使用VLLSHOP服务。</div>
        <br>
        <h3>三、声明</h3>
        <div>1. 您理解并认可，VLLSHOP仅协助建立网站，不参与终端消费者与买家之间的实际交易。</div>
        <br>
        <div>2. 您不得向我们主张任何因您非法使用VLLSHOP而导致后果的责任。</div>
        <br>
        <div>3. 我们不转让或保证转让产品从卖家到买家的所有权。除非买卖双方另有约定，买家从卖家实际收到该产品后即成为该产品的合法所有人。此外，VLLSHOP的运作可能受到许多超出我们控制范围的因素的干扰，因此，在法律允许的范围内，我们排除所有隐含的保证、条款和条件。我们不对因您使用我们的平台而产生的任何金钱、商誉或声誉损失，或任何特殊的、间接的或相应的损害负责。因使用VLLSHOP产生的风险由您自行承担。</div>
        <br>
        <div>4. 如果买卖双方之间发生争议，您理解并同意不会向我们（包括我们的员工，分包商和所有关联方）主张赔偿或要求以任何方式承担与此类争端有关的责任。</div>
        <br>
        <div>5.您在使用VLLSHOP的过程中与其他用户发生争议的，您或其他任何一方均有权选择以下途径解决：</div>
        <br>
        <div>5.1 与争议相对方自主协商；</div>
        <br>
        <div>5.2 请求有关调解组织调解；</div>
        <br>
        <div>5.3 向有关行政部门投诉；</div>
        <br>
        <div>5.4 根据与争议相对方达成的仲裁协议（如有）提请仲裁机构仲裁；</div>
        <br>
        <div>5.5 向人民法院提起诉讼</div>
        <br>
        <div>6.我们无法控制其他用户通过我们的系统提供的信息。请您仔细浏览并审慎阅读和理解其他用户在VLLSHOP上发布的信息，并应注意和自行承担因信息真实性导致的风险。通过使用本平台，您同意接受这些风险。对您在使用VLLSHOP时的疏忽导致的损失，我们不承担相关负责。</div>
        <br>
        <div>7.通过VLLSHOP提供的某些服务可能由第三方提供。通过使用任何产品,服务或功能或平台本身，您承认并同意，我们可以与该第三方共享这些信息和数据。详情请您查阅<a href="https://www.vllshop.com/treaty/privacyPolicy" target="_blank">《VLLSHOP隐私政策》</a>。</div>
        <br>
        <h3>四、知识产权及所有权</h3>
        <div>1. 您理解并同意，将您在VLLSHOP上提供、上传、创作、发表的任何信息及其演绎作品的知识产权等全部合法权利权益，免费授予VLLSHOP及其关联公司至保护期终止为止、不可撤销的、全球范围的、排他的许可使用权，VLLSHOP及关联公司有权以自身名义对第三方侵权行为进行取证及提起诉讼用于商业用途。VLLSHOP及其关联公司有权储存、使用、复制、修订、编辑、发布、展示、翻译、分发、推广、出版、发行、信息网络传播及删除以上信息内容，有权改变制作演绎作品，并以已知或日后开发的形式、媒体或技术将上述信息纳入其他作品内。</div>
        <br>
        <div>2. 您有权向VLLSHOP提交申请，在第三方网站或通过其他人发布在VLLSHOP提交的内容。您同意VLLSHOP对任何使用争议不承担任何责任。如果您选择在第三方网站上显示您在VLLSHOP提交的内容，应保证该该内容必须附有跳转至VLLSHOP网站的链接。</div>
        <br>
        <div>3. 您应确保在VLLSHOP上发表的各类信息内容均不涉及侵犯第三方肖像、隐私、个人信息、知识产权或其他合法权益。否则VLLSHOP有权随时采取必要措施，包括但不限于删除、断开链接等。如因您上传的内容存在侵权问题或者任何其他的权利瑕疵而引起权利纠纷的，您应当自行解决该等纠纷并独立承担相应的责任。如有任何第三方向我们主张授权内容的权益，您应当为我们积极向第三方抗辩，或者按照我们的要求，为我们向第三方抗辩提供必要的配合与协助（包括但不限于提供与授权内容有关的权利证明文件、资料、信息等）；如因您上传的内容存在侵权问题或者任何其他的权利瑕疵而给我们造成损失的，您应当赔偿我们遭受的全部损失，并消除因前述授权内容的侵权或权利瑕疵问题给我们造成的不良影响。</div>
        <br>
        <div>4. VLLSHOP所展示的各运营系统由VLLSHOP自主开发、运营提供技术支持，并对VLLSHOP服务的开发和运营等过程中产生的所有数据和信息等（包括但不限于技术、软件、程序、数据及其他信息（包括但不限于文字、图像、图片、照片、音频、视频、图表、色彩、版面设计、电子文档）和经我们创作、整理或以其他方式（如人工智能）生成内容的所有知识产权（包括但不限于版权、商标权、专利权、商业秘密等）及相关权益）享有全部权利。VLLSHOP提供各项服务时所依托软件的著作权、专利权，所使用的商标、设计、页面标题、按钮图标、商业形象、脚本的知识产权等全部合法权利权益均归VLLSHOP或相关权利人所有。我们享有的所有知识产权和相关权益，不因您的任何使用行为而发生任何的权利转移。VLLSHOP上所有内容的汇编是VLLSHOP的排他财产。</div>
        <br>
        <div>5. 未经我们授权，任何人不得擅自使用（包括但不限于复制、传播、展示、镜像、上传、下载、修改、出租，以及通过任何机器人、爬虫、蜘蛛等任何自动程序、脚本、软件或设备进行操作等）亦不得修改、改编、翻译VLLSHOP产品与/或服务所使用的软件、技术、材料等，或者创作与VLLSHOP产品与/或服务相关的派生作/产品或衍生品，或者利用VLLSHOP产品与/或服务之部分或全部向任何第三方提供产品与/或服务，不得通过反向工程、反编译、反汇编或其他类似行为获得VLLSHOP产品与/或服务的源代码等，否则，我们有权追究您的责任，要求您赔偿由此给我们或其他人造成的损失，并有权视情况将上述行为移交相关政府部门处理。</div>
        <br>
        <div>6. 请您在任何情况下都不得私自使用、擅自删除、掩盖或更改我们的包括但不限于“VLLSHOP”等在内的任何商业标识（包括我们及关联公司的Logo、“VLLSHOP”等文字、图形及其组合，以及其他标识、徵记、产品和服务名称）、服务标记、商号、域名、网站名称或其他显著品牌特征等（以下简称“标识”）。未经我们的事先书面同意，您不得将本条款前述标识以单独或结合任何方式展示、使用或申请注册商标、进行域名注册等，也不得向他人明示或暗示您有权展示、使用、或其他有权处理这些标识，亦不得实施误使他人认为您与这些标识有关联的其他行为。由于您违反本协议使用我们的上述标识等给我们或他人造成损失的，应由您承担全部法律责任。</div>
        <br>
        <div>7. 您在使用VLLSHOP产品与/或服务的过程中，我们仅授予您可撤销的、有限的、不可转让及非排他性的使用授权。您仅可为访问/使用VLLSHOP产品与/或服务的目的而使用VLLSHOP。</div>
        <br>
        <h3>五、关于第三方网站/第三方服务的链接</h3>
        <div>1. 您理解并了解，VLLSHOP上任何第三方网站/第三方服务的链接均不受我们控制，我们对任何链接网站/服务的内容，包括但不限于链接网站/服务中包含的任何链接，或对链接网站/服务的任何更改或更新不承担任何责任。我们向您提供这些链接仅供参考，并不意味着我们认可链接网站的内容或与其运营商有任何关联。您与VLLSHOP之间的服务协议并不包括您与链接网站的互动，您应该仔细审查任何第三方网站的用户协议和隐私政策。</div>
        <br>
        <div>2. 对于VLLSHOP中的任何通讯服务，您应当谨慎提供任何关于您或者您家人的个人信息，包括但不限于公告板服务、聊天区域、社区与/或其他旨在使您能够与其他用户或公众进行沟通交流的服务（以下称“交流服务”）。我们无法控制、编辑任何交流服务中用户或公众提交的内容和信息。因此，在法律法规允许的前提下，我们不承担任何有关交流服务和您因参与任何交流服务而导致的任何行为的责任。</div>
        <br>
        <div>3. 用户资料是指您为经营网店的需要建立会员制度或其他机制，基于该等制度所收集的终端用户的个人信息或者其他信息。您在此保证并承诺前述取得的用户资料已获得终端用户的必要授权和/或同意且对于该等资料的取得、保存及使用应当满足相关法律、法规及本合同对于个人信息保护的要求。</div>
        <br>
        <h3>六、个人信息保护</h3>
        <div>1. VLLSHOP非常重视保护您的个人信息。VLLSHOP希望通过《VLLSHOP隐私政策》向您清楚的介绍对用户个人信息的保护和处理，因此建议您完整地阅读<a href="https://www.vllshop.com/treaty/privacyPolicy" target="_blank">《VLLSHOP隐私政策》</a>。此外，我们不会在未经您明确同意的情况下向第三方出售或披露您的个人信息。</div>
        <br>
        <div>2. 对于VLLSHOP中的任何通讯服务，您应当谨慎提供任何关于您或者您家人的个人信息，包括但不限于公告板服务、聊天区域、社区与/或其他旨在使您能够与其他用户或公众进行沟通交流</div>
        <br>
        <div>3. 用户资料是指您为经营网店的需要建立会员制度或其他机制，基于该等制度所收集的终端用户的个人信息或者其他信息。您在此保证并承诺前述取得的用户资料已获得终端用户的必要授权和/或同意且对于该等资料的取得、保存及使用应当满足相关法律、法规及本合同对于个人信息保护的要求。</div>
        <br>
        <h3>七、责任限制</h3>
        <div>1. 双方理解因为如下原因导致合同履行瑕疵、履行障碍等情形不构成合同的违约，双方对此不承担违约责任：</div>
        <br>
        <div>1.1 因地震、台风、战争、武装冲突、暴动、政府行为等不可抗力事件；及</div>
        <br>
        <div>1.2 因黑客攻击、计算机病毒入侵、电信部门基数管制、移动运营商平台故障、网络故障、电力供应故障等第三方因素。</div>
        <br>
        <div>2. 您了解出现如下情况，并不构成VLLSHOP的违约，您应当自行承担相应的后果：</div>
        <br>
        <div>2.1 VLLSHOP不保证传输系统稳定且绝无中断的情形，您应自行采取备份存档等保护措施避免信息的损毁等后果；及</div>
        <br>
        <div>2.2 受限于一国网络电信政策或网络防火墙设定，可能出现终端用户无法访问网站全部内容的情形。</div>
        <br>
        <div>3. VLLSHOP可能不定期对系统进行维护、升级、更新、迁移，由此可能会导致本服务的暂停或中止。若出现前述事由，VLLSHOP以当根据本合同第十四条约定通知您，并告知您暂停或中止的预计起止时间（暂停或终止的时间以VLLSHOP系统记录的时间为准）。若由上述原因导致本服务的暂停或中止的，导致您的终端用户连续十二（12）小时无法访问您的网店或购买商品，VLLSHOP将会延长您使用本服务的期限（中断时间每超过12小时即延长一天使用期限）。</div>
        <br>
        <div>4. 您同意，VLLSHOP不对因本协议或本服务产生的利润损失、数据丢失、设备停机或者特殊的、间接的、附带的、偶发的或惩罚性的损失向您承担责任；在本合同下VLLSHOP对您的累计损失赔偿责任最高不应超过VLLSHOP在赔偿事由发生时前12个月内累计向您收取的套餐费用。</div>
        <br>
        <h3>八、管辖法律及争议解决</h3>
        <div>1. 本合同的成立、生效、履行、解释及纠纷解决，适用中国法律，并明确排除其他冲突法规范的适用。</div>
        <br>
        <div>2. 各方应尝试通过友好协商和谈判方式解决因本合同而产生的或者与本合同有关的任何争议、争论、权利请求或意见分歧（单称或合称“争议”）。因履行本合同所发生的争议并未在本合同内有所规定和解释的，双方应友好协商。协商不成时，任何一方均可向深圳市福田区人民法院提起诉讼。该法院享有排他性管辖权。</div>
        <h3>九、通知</h3>
        <div>1. 与本合同有关的任何通知或其他通信应为书面形式，并以中文书面，通过专人递送或快递（此情形下须向相关接收人发送有关即将送达通知的电邮），或者通过电子邮件方式送达。</div>
        <br>
        <div>2. 您有义务向VLLSHOP提供真实、有效的联系方式（包括但不限于联系电话、联系地址、电子邮箱），VLLSHOP有权以前述任何一个或多个联系方式向您发送通知。</div>
        <br>
        <div>3. VLLSHOP以电子邮件、系统消息向您发送信息后，在发送成功后应当被认为已经成功送达。若以纸质书面的方式发出的通知，VLLSHOP按照您提供的地址进行邮寄后的第五（5）日视为成功送达。</div>
        <br>
        <div>4. 您于本合同所提供的联系方式和信息一旦有变更，应以书面通知VLLSHOP，若由您造成的延迟通知问题，导致VLLSHOP发出通知无法送达时，则以该通知发出后，即视为已合法送达。因自然灾害或其他不可抗力因素所导致的，不在此规定内。</div>
        <br>
        <h3>十、通用条款</h3>
        <div>1. 您理解并同意，您与我们之间不存在任何合资、合伙、雇佣或代理关系。本协议的履行受现行法律和法律程序的约束。本协议中的任何内容均不损害我们遵守与您使用本平台有关的政府、法院和执法部门的请求或要求，或提供或收集与您使用VLLSHOP有关的信息的权利</div>
        <br>
        <div>2. 根据相关法律法规，如果本协议任何部分是确定为无效或无法执行包括但不限于免责声明和责任限制条款，则无效或无法执行的条款将被视为无效，其余部分继续有效。</div>
        <br>
        <div>3. 未经我方书面同意，您不得以任何形式转让本协议中的任何权利义务，否则我们有权拒绝承认并继续提供服务。</div>
        <br>
        <div>4. 本公司未能执行或行使服务协议的任何规定或任何相关权利，并不构成对该规定或权利的放弃。本协议中未明确授予的任何权利均予以保留。我们可能通过电子邮件、快递邮件、平台公告或目前已知或未来开发的其他合理方式向您发送通知，包括有关条款和条件变更的通知。印刷版本的协议,任何以电子形式的通知,将容许在司法或行政程序中作为根据或与本协议相同程度的适用。</div>
        <br>
        <h3>十一、免责声明</h3>
        <div>我们的某些功能可能会向您提供如何改进店铺增强用户体验的信息和建议，但上述信息和建议仅供您参考，不应用于个人、医疗、法律或财务决策，您应咨询合适的专业人士，以获得适合您情况的具体建议。我们将努力保持网站的稳定性和可使用性</div>
        <br>
        <h3>十二、保密条款</h3>
        <div>双方因本合同意愿、执行、履行及其他相关事项而了解或持有对方技术上、营业上、业务上、组织上、财务上、及其他所有营业秘密或个人信息（统称“保密资料”），除事前取得对方书面同意外，应负有保密义务，本合同终止后亦同。但下列所规定的信息不属于保密资料：双方已在其他公开渠道公开的资料；双方通过正当、公开渠道获取的资料；可证明对方披露前，第三方已正当持有或已知悉的；经对方书面同意后不属于保密资料的。</div>
        <br>
        <h3>十三、联系方式</h3>
        <div>1. 如对本政策内容有任何疑问、意见或建议，您可以联系VLLSHOP客户服务热线：4008299969；</div>
        <br>
        <div>2. 我们还设立了个人信息保护专职部门，您可以通过以下方式与我们取得联系。</div>
        <br>
        <div>2.1 邮箱：user@vllshop.com</div>
        <br>
        <div>2.2 常用办公地址：深圳市南山区航空航天大厦1号楼7003（收）</div>
        <br>
      </div>
    </a-col>
  </a-row>
</template>

<script>
export default {
  name: 'UserAgreement'
}
</script>

<style scoped>

</style>
